import { IoPersonCircle } from "@react-icons/all-files/io5/IoPersonCircle";
import { classNames } from "repoV2/utils/common/render/classNames";
import React from "react";
import styles from "./account.module.scss";
import { IAccount } from "./IAccount";

export const Account = ({
    hideComponent,
    accountItems,
    accountIconColorScheme,
}: IAccount.IProps) => {
    if (hideComponent) return null;

    return (
        <div className={classNames(styles.container)}>
            <IoPersonCircle
                className={classNames(
                    styles.accountIcon,
                    styles.button,
                    accountIconColorScheme
                )}
                size={40}
            />
            <div className={styles.dropdownContainer}>
                <div className={styles.dropdown}>
                    {accountItems.map(accountItem =>
                        accountItem?.showItem ? (
                            <a
                                key={accountItem.content.toString()}
                                {...accountItem.props}
                                className={classNames(
                                    styles.dropdownItem,
                                    styles.button
                                )}
                            >
                                {accountItem.content}
                            </a>
                        ) : null
                    )}
                </div>
            </div>
        </div>
    );
};
