import { IUser } from "@Interfaces";
import {
    BUTTON_DESIGNS,
    FETCH_STATUS,
    ITemplateKey,
    NOTIFICATION_TYPES,
    isEmpty,
    useNotifications,
} from "@Utils";
import React from "react";
import { classNames } from "repoV2/utils/common/render/classNames";
import { parseDateTime } from "@Utils/parseDateTime";
import { Button } from "../../../..";
import styles from "./notificationsPanel.module.scss";

export const NotificationsPanel = ({
    template,
    containerRef, // to handle click outside notifications container
    closeNotificationsPanel,
    openNotificationMessagePopup,
}: {
    template?: ITemplateKey;
    containerRef: React.RefObject<HTMLDivElement>;
    closeNotificationsPanel: () => void;
    openNotificationMessagePopup: (customMaild: number) => void;
}) => {
    const {
        fetchNotificationsStatus,
        notificationsData,
        showLoadMoreButton,
        onLoadMoreClick,
    } = useNotifications({});

    let notificationDate = ""; // used in notificationsData.data.map

    return (
        <div className={styles.root}>
            <div
                className={classNames(
                    styles.container,
                    template && styles[template]
                )}
                ref={containerRef}
            >
                <div
                    className={classNames(
                        styles.header,
                        styles.uniformVerticalPadding,
                        styles.uniformHorizontalPadding
                    )}
                >
                    <div className={styles.boldText}>Notifications</div>
                    <Button
                        className={classNames(
                            styles.closeButton,
                            styles.plainTextButton
                        )}
                        design={BUTTON_DESIGNS.PLAIN_TEXT}
                        content={<>&#10006;</>}
                        onClick={closeNotificationsPanel}
                    />
                </div>
                <div className={styles.notificationsContainer}>
                    {!isEmpty(notificationsData.data) ? (
                        <>
                            {notificationsData.data.map(
                                (notificationData: IUser.INotificationData) => {
                                    let isNewDate = false;
                                    const currentNotificationDate =
                                        parseDateTime(
                                            notificationData.created_at,
                                            "MMM dd, KK:mm a"
                                        );
                                    if (
                                        currentNotificationDate !==
                                        notificationDate
                                    ) {
                                        notificationDate =
                                            currentNotificationDate;
                                        isNewDate = true;
                                    }
                                    return (
                                        <div
                                            key={
                                                notificationData.user_notification_id
                                            }
                                        >
                                            {isNewDate ? (
                                                <div
                                                    className={classNames(
                                                        styles.uniformSmallVerticalPadding,
                                                        styles.uniformHorizontalPadding
                                                    )}
                                                >
                                                    {notificationDate}
                                                </div>
                                            ) : null}

                                            {notificationData.type ===
                                            NOTIFICATION_TYPES.MESSAGE ? (
                                                <div
                                                    className={classNames(
                                                        styles.uniformSmallVerticalPadding,
                                                        styles.uniformHorizontalPadding,
                                                        styles.notificationContainer,
                                                        styles.notificationMessageContainer
                                                    )}
                                                >
                                                    <>
                                                        <div
                                                            className={
                                                                styles.notificationMessage
                                                            }
                                                        >
                                                            {" "}
                                                            {
                                                                notificationData.notification_heading
                                                            }
                                                            &nbsp;
                                                        </div>
                                                        <Button
                                                            className={
                                                                styles.plainTextButton
                                                            }
                                                            design={
                                                                BUTTON_DESIGNS.PLAIN_TEXT
                                                            }
                                                            content="Read More"
                                                            onClick={() => {
                                                                openNotificationMessagePopup(
                                                                    notificationData.custom_mail_id ||
                                                                        0
                                                                );
                                                            }}
                                                        />
                                                    </>
                                                </div>
                                            ) : null}

                                            {notificationData.type ===
                                            NOTIFICATION_TYPES.REMINDER ? (
                                                <div
                                                    className={classNames(
                                                        styles.uniformSmallVerticalPadding,
                                                        styles.uniformHorizontalPadding,
                                                        styles.notificationContainer,
                                                        styles.notificationReminderContainer
                                                    )}
                                                    onClick={
                                                        closeNotificationsPanel
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.notificationReminder
                                                        }
                                                    >
                                                        {" "}
                                                        Reminder -&nbsp;
                                                        {
                                                            notificationData.listing__title
                                                        }
                                                        &nbsp;starting at
                                                        {notificationData.notification_sub_heading.replace(
                                                            notificationData.listing__title ||
                                                                "",
                                                            ""
                                                        )}
                                                    </div>
                                                </div>
                                            ) : null}

                                            {notificationData.type ===
                                            NOTIFICATION_TYPES.BONUS_OFFERING_ADDED ? (
                                                <div
                                                    className={classNames(
                                                        styles.uniformSmallVerticalPadding,
                                                        styles.uniformHorizontalPadding,
                                                        styles.notificationContainer,
                                                        styles.notificationReminderContainer
                                                    )}
                                                    onClick={
                                                        closeNotificationsPanel
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.notificationReminder
                                                        }
                                                    >
                                                        {" "}
                                                        {
                                                            notificationData.notification_heading
                                                        }{" "}
                                                        :-
                                                        {
                                                            notificationData.notification_sub_heading
                                                        }
                                                    </div>
                                                </div>
                                            ) : null}

                                            {notificationData.type ===
                                            NOTIFICATION_TYPES.POST_ADDED ? (
                                                <div
                                                    className={classNames(
                                                        styles.uniformSmallVerticalPadding,
                                                        styles.uniformHorizontalPadding,
                                                        styles.notificationContainer,
                                                        styles.notificationReminderContainer
                                                    )}
                                                    onClick={
                                                        closeNotificationsPanel
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.notificationReminder
                                                        }
                                                    >
                                                        {" "}
                                                        {
                                                            notificationData.notification_heading
                                                        }{" "}
                                                        :-
                                                        {
                                                            notificationData.notification_sub_heading
                                                        }
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    );
                                }
                            )}
                            {showLoadMoreButton ? (
                                <div className={styles.loadMoreContainer}>
                                    <Button
                                        design={BUTTON_DESIGNS.ROUNDED_CORNERS}
                                        content={
                                            fetchNotificationsStatus ===
                                            FETCH_STATUS.FETCHING
                                                ? "Loading..."
                                                : "Load more"
                                        }
                                        disabled={
                                            fetchNotificationsStatus ===
                                            FETCH_STATUS.FETCHING
                                        }
                                        onClick={onLoadMoreClick}
                                    />
                                </div>
                            ) : null}
                        </>
                    ) : (
                        <div className={styles.emptyState}>
                            No Notifications to show... YAY!
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

NotificationsPanel.defaultProps = {
    template: undefined,
};
