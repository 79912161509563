import React from "react";
import { INavLink } from "./NavLinks";

export const LinkItem = (props: INavLink.LinkIProps) => {
    const { content, ...restProps } = props;

    return <a {...restProps}>{content}</a>;
};

export const NavLink = (props: INavLink.IProps) => {
    const { showItems, navbarItems, styleProps, classes } = props;

    const links = showItems.map(navbarItemKey => {
        const navbarItem = navbarItems[navbarItemKey];
        const canShow = navbarItem.showItem;

        if (!canShow) return null;

        return (
            <React.Fragment key={navbarItemKey}>
                <LinkItem
                    content={navbarItem.content}
                    style={styleProps}
                    className={classes}
                    {...navbarItem.props}
                />
            </React.Fragment>
        );
    });

    return <>{links}</>;
};
